jQuery(function($) {
    "use strict";
    // Variables
    var breakpt = 992;
    var breakpt_sm = 540;
    var lastScroll = 0;
    var popToTop = 0;

    // Cookies
    function createCookie(name, value, days) {
        var expires = "";
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toUTCString();
        }
        switch (name) {
            case 'cookiebar':
                deleteCookie("nocookie");            
                break;
            case 'nocookie':
                deleteCookie("cookiebar");
                break;
            case 'social_cookie':
                deleteCookie("no_social_cookie");
                break;
            case 'no_social_cookie':
                deleteCookie("social_cookie");
                break;
        }
        document.cookie = name + "=" + value + expires + "; path=/";
    }

    function deleteCookie(name) {
        document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    }

    window.readCookie = function(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    }

    function eraseCookie(name) {
        createCookie(name, "", -1);
    }

	function gaAnalytics(i, s, o, g, r, a, m) {
		i['GoogleAnalyticsObject'] = r;
		i[r] = i[r] || function() {
			(i[r].q = i[r].q || []).push(arguments)
		}, i[r].l = 1 * new Date();
		a = s.createElement(o),
			m = s.getElementsByTagName(o)[0];
		a.async = 1;
		a.src = g;
		m.parentNode.insertBefore(a, m);
	}

    function gtm(w,d,s,l,i) {
      w[l]=w[l]||[];
      w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});
      var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
      j.async=true;
      j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
      f.parentNode.insertBefore(j,f);
    }

    // analytics
    var cookiebar = readCookie('cookiebar');
    if(cookiebar) {
        gaAnalytics(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga');
        googleAnalytics();
    }
    function googleAnalytics() {
        $.ajax({
            url: '/codeUA',
            success: function (data) {
                if (data != false) {
                    ga('create', data, 'auto');
                    ga('send', 'pageview');
                } else {
                    console.log("Problème de codeUA");
                }
            },
            error: function() {
                console.log("Problème de codeUA");
            }
        });
    }
    $.ajax({
        url: '/codeGTM',
        success: function (data) {
            if (data != false) {
                gtm(window, document, 'script', 'dataLayer', data);
            } 
        },
        error: function() {
        }
    });

    // Detect IE version
    function getIEVersion() {
        var agent = navigator.userAgent;
        var reg = /MSIE\s?(\d+)(?:\.(\d+))?/i;
        var matches = agent.match(reg);
        if (matches != null) {
            return { major: matches[1], minor: matches[2] };
        }
        return { major: "-1", minor: "-1" };
    }
    var ie_version = getIEVersion();
    var is_ie = ie_version.major;
    var is_ie11 = !!navigator.userAgent.match(/Trident.*rv\:11\./);

    // Display or Hide btn Scroll To Top
    function displayScrollToTopButton() {
        if ($('#btnToTop').length) {
            if ($(window).scrollTop() > 0) {
                $('#btnToTop').addClass('scrolled');
            } else {
                $('#btnToTop').removeClass('scrolled');
            }
        }
    }
    // Redirection to article url
    function redirectUrlArticles() {
        var article = $(this),
            url = article.find('a').attr('href');
        window.location.href = url;
    }

    // Custom Malibu Scrollbar for popup
    function popupScrollBar() {
        if ($('.popup').length) {
            setTimeout(function() {
                var heightContainer = $('.popup>.container').height();
                var heightBody = $('.popup>.container>.popup-body').height();
                if (heightBody > heightContainer) {
                    $('.popup>.container .popup-body').css({
                        'max-height': heightContainer + 'px'
                    });
                    $('.popup>.container .popup-body').mCustomScrollbar({
                        scrollbarPosition: 'outside'
                    });
                }
            }, 100);
        }
    }
    // Open popup
    function showPopup(popup) {
        var popToTop = $(window).scrollTop();

        $(document.body).addClass('no-scroll').css({
            'top': '-' + popToTop + 'px'
        });

        $(popup).css({
            'display': 'flex'
        });
        popupScrollBar();
    }

    // Calcul
    window.calculTranquileo = function(arrayAmountToAdd) {

            var initAmount = 24.90,
                amountToAdd = 0;

            $.each(arrayAmountToAdd, function(key, val) {
                amountToAdd += parseInt(val);
            });

            var amountbeforeImpot = initAmount + amountToAdd,
                amountAfterImpot = amountbeforeImpot / 2;
            $('#popup-simulation .amount-before-impot .amount').html($.number(amountbeforeImpot, 2, ',', ' '));
            $('#popup-simulation .amount-after-impot .amount').html($.number(amountAfterImpot, 2, ',', ' '));
            $('.result-block').removeClass('hidden');
            popupScrollBar();

            return [amountbeforeImpot, amountAfterImpot];
    }
        // Sticky header
    function stickyHeader(window) {
        var st = window.scrollTop();
        if (st < 200) {
            $('header').removeClass('sticky-header-on sticky-header-off');
        } else {
            if (st > lastScroll) {
                $('header').removeClass('sticky-header-on');
                $('header').addClass('sticky-header-off');
            } else {
                $('header').removeClass('sticky-header-off');
                $('header').addClass('sticky-header-on');
            }
        }
        lastScroll = st;
    }

    $( ".dropdown" ).mouseover(function() {
        $('.dropdown-menu').css("display", "block");
    });
    $( ".dropdown" ).mouseout(function() {
        $('.dropdown-menu').css("display", "none");
    });
    $(".dropdown").on("click",function(){
        $('.dropdown-menu').toggle();
    });
    // Cookiebar

    function showMoreCookiebar() {
      if($('#cookiebar').length) {
        $('#info-cookiebar').addClass('hide-cookiebar');
        if(readCookie('cookiebar')) {
            document.getElementById("rgpd-checkbox").checked = true;
        } else {
            document.getElementById("rgpd-checkbox").checked = false;
        }
        if(readCookie('social_cookie')) {
            document.getElementById("social-checkbox").checked = true;
        } else {
            document.getElementById("social-checkbox").checked = false;
        }
        $('#more-cookiebar').addClass('show-more-cookiebar');
      }
    }

    function hideCookiebar() {
            if ($('#cookiebar').length) {
                $('#cookiebar').addClass('hide-cookiebar');
            }
        }
        /*=====================================
         =            INIT FUNCTION            =
         =====================================*/
    $(document).ready(function() {
        function loadVideo() {
            if(readCookie('social_cookie')) {
                var player;
                player = plyr.setup()[0];
                player.on('play', function (event) {
                    $('.plyr__controls').css({'display': 'flex'});
                    $('.main-title').fadeOut();
                    $('.plyr__video-wrapper').addClass('isPlaying');
                });
                player.on('pause', function (event) {
                    $('.main-title').show();
                });
                player.on('ended', function (event) {
                    $('.plyr__video-wrapper').removeClass('isPlaying');
                });
            }
        }
        // CookieBar
        var nocookie = readCookie('nocookie');
        if (cookiebar) {
            $('#cookiebar').addClass('hide-cookiebar');
        } else if (nocookie) {
            $('#cookiebar').addClass('hide-cookiebar');
        }
        // icon-burger
        $('#icon-burger').click(function(e) {
            e.preventDefault();
        });
        // Cookies bar
        $('#more-rgpd').click(function() {
          showMoreCookiebar();
        });
        // Cookies banner pop
        $('#banner-pop').click(function(e) {
            e.preventDefault();
            $('#cookiebar').removeClass('hide-cookiebar');
            $('#info-cookiebar').removeClass('hide-cookiebar');
            $('#more-cookiebar').removeClass('show-more-cookiebar');
        });

        $('#cookiebar .btn-close').click(function() {
            //createCookie('nocookie', true, '');
            $('#info-cookiebar').removeClass('hide-cookiebar');
            $('#more-cookiebar').removeClass('show-more-cookiebar');
          //  hideCookiebar();
        });
        //add cookie
        $('#accept-rgpd').click(function() {
            createCookie('cookiebar', true, 360);
            createCookie('social_cookie', true, 360);
            hideCookiebar();
            $(".yt-img-container").addClass('hidden');
            var plyrCntr = $(".plyr-container").find("[data-type='youtube']").length;
            if(plyrCntr == 0) {
                createVideoDiv();
                loadVideo();
            } else {
                loadVideo();
            }
            gaAnalytics(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga');
            googleAnalytics();
            //ga('send', 'pageview');
        });
        $('#accept-rgpd-checkbox').click(function() {
          if ($('#rgpd-checkbox').is(":checked")) {
            createCookie('cookiebar', true, 360);
            hideCookiebar();
            gaAnalytics(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga');
            googleAnalytics();
            //ga('send', 'pageview');
          } else {
              var ca = document.cookie.split(';');
              var len = ca.length;
              for(var i = 0; i < len; i++) {
                  var cookiename = ca[i].split('=');
                  document.cookie = cookiename[0] + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
                  document.cookie = cookiename[0] + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=.tranquileo.localhost; path=/;";
                  document.cookie = cookiename[0] + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=.appserviceenvironment.net; path=/;";
                  document.cookie = cookiename[0] + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=.axa-assistance.fr; path=/;";
              }
              createCookie('nocookie', true, 1);
              hideCookiebar();
          } 
          if ($('#social-checkbox').is(":checked")) {
              createCookie('social_cookie', true, 360);
              $(".yt-img-container").addClass('hidden');
              var plyrCntr = $(".plyr-container").find("[data-type='youtube']").length;
                if(plyrCntr == 0) {
                createVideoDiv();
                loadVideo();
                } else {
                    loadVideo();
                }
          } else {
              createCookie('no_social_cookie', true, 1);
              $('.plyr--youtube').remove();
              $(".yt-img-container").removeClass('hidden');
          }
        });
        function createVideoDiv() {
            var videoDiv = document.getElementsByClassName('plyr--youtube');  
            if(videoDiv.length == 0) {
               var cntr =  document.querySelector('.plyr-container');
               var newDiv = cntr.appendChild(document.createElement("div"));
               newDiv.setAttribute("data-type", "youtube");
               newDiv.setAttribute("data-video-id", "2mx9ZFvW794");
               $(".yt-img-container").addClass('hidden');
            }
        }
        //Burger Menu behaviour
        $('#icon-burger').click(function() {
            $(this).toggleClass('open');
        });
        $('#icon-burger-sticky').click(function() {
            $(this).toggleClass('open');
            $('#navbar-sticky-mobile').show();
        });
        // Redirection to article url
        $('.item-article').click(redirectUrlArticles);
        //Action button Scroll to Top
        displayScrollToTopButton();
        $('#btnToTop').click(function() {
            $('html, body').animate({ scrollTop: '0px' }, 300);
        });
        // Display popup
        $('.open-popup').click(function(e) {
            e.preventDefault();
            showPopup($(this).attr('href'));
        });

        //Close popup
        $('.btn-close-popup').click(function(e) {
            e.preventDefault();
            $(document.body).removeClass('no-scroll');
            $(window).scrollTop(popToTop);
            var popup = $(this).attr('href');
            $(popup).hide();
            $(popup + ' .result-block').addClass('hidden');
        });

        //Simulation calcul
        $('.btn-form-popup').click(function(e) {
            e.preventDefault();
            var popup = $(this).attr('href');
            var arrayAmountToAdd = [];

            $('#popup-simulation .popup-form input[type=checkbox]:checked').each(function() {
                arrayAmountToAdd.push($(this).val());
            });

            calculTranquileo(arrayAmountToAdd);
        });
        //Check simulation checkboxes
        $('#popup-simulation input[type=checkbox]').click(function() {
            $(this).toggleClass('unchecked');

        });


        /*=====================================
         =            TAGGAGE      =
         =====================================*/
        $('#header-btn-subscribe').click(function() {
            ga('send', 'event', 'Header', 'click-link', 'Souscription');
        });

        $('#popup-btn-subscribe').click(function() {
            ga('send', 'event', 'Simulation', 'click-link', 'Souscription');
        });

        $('#footer-ddl-documentation').click(function() {
            ga('send', 'event', 'Footer', 'click-link', 'Brochure');
        });
        // Slider FAQ
        if($('.slider-faq-list').length) {
            $('#faq-list').slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                autoplay: false,
                dots: false,
                arrows: false,
                fade: true,
                adaptiveHeight: true,
                cssEase: 'linear',
                speed: 500,
                asNavFor: '.faq-slider-nav'
            });
            $('.faq-slider-nav').slick({
                slidesToShow: 4,
                asNavFor: '#faq-list',
                dots: false,
                centerMode: false,
                arrows: false,
                focusOnSelect: true,
                responsive: [
                    {
                        breakpoint: breakpt,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                            infinite: true,
                            dots: false,
                            arrows: true
                        }
                    },
                    {
                        breakpoint: breakpt_sm,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            infinite: true,
                            dots: false,
                            arrows: true
                        }
                    }
                ]
            });
        }
        // Adapt height on collapse
        if($('#faq-list .slick-list').length) {
            $('#faq-list .panel-heading a').click(function() {
                var that = $(this);
                setTimeout(function(){
                    var totalheight = that.closest('.panel-group').height();
                    $('#faq-list .slick-list').animate({
                        'height': totalheight + 'px'
                    }, 200);
                }, 500);

            });
        }
        //footer menu
        $('.nav-item span').click(function() {
            if($(this).hasClass( "active" )) {
                $(this).parent().next( "ul" ).toggle();
                $(this).removeClass("active");
            } else {
                $(".nav-item span").removeClass("active");
                $(this).addClass("active");
                $( ".nav-item > ul" ).css( "display", "none" );
                $(this).parent().next( "ul" ).toggle();
            }
        });
    });
    /*=====  End of INIT FUNCTION  ======*/
    /*=====================================
     =            RESIZE FUNCTION          =
     =====================================*/
    $(window).resize(function() {});
    /*=====  End of RESIZE FUNCTION  =====*/
    /*=====================================
     =            SCROLL FUNCTION          =
     =====================================*/
    $(window).scroll(function() {

        //Button Scroll to Top
        displayScrollToTopButton();
        //Sticky header
        if (!is_ie11 && is_ie == -1) {
            stickyHeader($(this));
        }
    });
    /*=====  End of SCROLL FUNCTION  =====*/
});
